import React from "react";
import testimonialData from "./testimonialData.json";

function Testimonials(data) {
	const dataList = (data) =>
		data.map((item, i) => {
			return (
				<li key={i}>
					<blockquote>
						<p className="quote">{item.quote}</p>
						<p className="author">{item.author}</p>
						{item.role && <p className="author-details">{item.role}</p>}
					</blockquote>
				</li>
			);
		});

	return (
		<section className="testimonials" id="testimonials">
			<div name="testimonials">
				<h2>Testimonials</h2>
			</div>
			<ul>{dataList(testimonialData)}</ul>
		</section>
	);
}

export default Testimonials;
